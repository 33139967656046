/** @format */
import {poster} from './Web3 Uni Roundtable Discussion/Aspose.Words.3efcec20-7b17-4338-a49e-2989c0590df6.001.png'

export const demoData = {
	id: null,
	title: "Web3 Uni Roundtable Discussion",
	description: "",
	publishTime: "",
	updateTime: "",
	content: `
🕰️ **Time:**  10/05/2023 9pm (AEST)  
🕹️ **Head over to:** [https://twitter.com/i/spaces/1rmxPkQYnPXJN](https://twitter.com/i/spaces/1rmxPkQYnPXJN)  



**Meeting Topic** : How to begin your journey in web3?  
**Host:** Australian Blockchain Club (ABC)   
**Co-Host:** Jay  
**Co-organizer:** Web3 World Marketing  
## Key Participants:
      
### Speakers:   
* Yan Meng (Co-founder of Solv)
* Shawn (Incubator at Seedao)
* Lightman (Co-founder of Web3 World Marketing and JR Academy)

### Representatives from Blockchain Associations and Student Organizations:
* Blockchain Association Representatives:  
	- EudemoniaCC from Tsinghua University Blockchain Association 
	- Claudia from Peking University Blockchain Association 
	- Bocai from RMIT 
	- Zane from University of Melbourne 

* Student Representatives:
	- Lok, External Affairs Minister of Chinese Students and Scholars Association at the University of Melbourne
	- Nemo, Vice External Affairs Minister of Monash Chinese Student Association
	- Rick, Student Representative from the University of Melbourne  

**Event Date:**   May 10th, 9pm (Australian Eastern Standard Time) / 7pm (Beijing Time)  
**Duration:** 2 hours

## Event Description:  
Hosted by the Australian Blockchain Club (ABC) and co-organized by Web3 World Marketing, 
this roundtable discussion aims to provide a vibrant platform for blockchain and Web3 enthusiasts, 
investors, and students to exchange ideas and knowledge through regular online and offline activities.  


## About Australian Blockchain Club (ABC):
Australian blockchain club (ABC) is an all-hybrid (online & offline) club brought to you by
individuals who have been involved in blockchain formany years. We are launching with the goal of providing a lively, communicative, and intellectually enriching platform for web3 and blockchain enthusiasts, regardless of whether you are new to all this or know the ins and outs thoroughly.


## Meeting Objectives:  
The Australian Blockchain Club (ABC) focuses on the emerging Web3 in Australia and aims to support students' enthusiasm and curiosity about Web3. ABC aims to help students better understand Web3, address challenges encountered during their exploration of Web3, and provide career guidance and insights for their future professional development. 


## Meeting Agenda: 
https://docs.google.com/document/d/1_k80F2TvGYfjCpB-oaVAo2I8yoXIBi3YTDai_OVfTGk/edit?usp=sharing


**Time: 9 pm-10 pm**  
* The future development trends and potential tracks in the Web3 industry, important topics such as the Cancun Upgrade. (Yan Meng, Shawn, Lightman, Bocai, CC)

* Reflections on the Hong Kong summit, its impact on the industry, and future development directions. (Yan Meng, Shawn, Lightman, Bocai)

* Q&A session with student representatives. (Lok, Nemo, Rick, Zane)

**Time: 10 pm-11 pm**
* Key factors for entrepreneurship in the Web3 field and elements of success. (Yan Meng, Shawn, Lightman, CC, Claudia, Zane)

* How can newcomers (university students) delve deeper into Web3? How can they integrate Web3 with their respective fields of study? (Bocai, CC, Zane)
* Q&A session with student representatives. (Lok, Nemo, Rick, Zane)

## Benefits for Participants:  
* 200 USDT
* Discounted tickets: Web3 & AI Hackfest: Hack the planet (https://www.web3hackfest.net/)









	

`,
};


